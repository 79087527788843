import Vue from 'vue'
import VueGoogleCharts from 'vue-google-charts'
import App from './App.vue'
import store from './store'

// Better touch behaviour on iOS
document.addEventListener("touchstart", () => {}, true);

Vue.config.productionTip = false;

Vue.use(VueGoogleCharts)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')