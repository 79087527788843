import { VaccineSDK } from "@/sdk"

function hourIntervalBetweenHours(start: number, end: number) {
    var count = 0;
    var current = start;
    while (current != end) {
        current++;
        if (current == 24) current = 0;
        count++;
    }
    return count;
}

function daysBetweenDateAndReportingDate(date: Date, reportingDate: Date, estimation: VaccineSDK.EstimationInfo) {
    const hourMs = 1000 * 60 * 60, dayMs = hourMs * 24;
    const dateTime = date.getTime();
    const fullDaysSince = Math.floor(Math.abs((reportingDate.getTime() - dateTime) / dayMs));

    // The number of hours in which this country will vaccinate daily (varies depending on number of timezones the country has)
    const totalVaccinationHours = hourIntervalBetweenHours(estimation.assumedHoursUTC.start, estimation.assumedHoursUTC.end);

    var nextEODTime = reportingDate.getTime();
    while (nextEODTime < dateTime) nextEODTime += dayMs;

    const hoursUntilNextEOD = (nextEODTime - dateTime) / hourMs;
    const hoursVaccinatedToday = totalVaccinationHours - hoursUntilNextEOD;

    const partialDay = date > reportingDate ? Math.min(1, Math.max(0, hoursVaccinatedToday / totalVaccinationHours)) : 0;

    return fullDaysSince + partialDay;
}

function daysSinceReportingDate(reportingDate: Date, estimation: VaccineSDK.EstimationInfo) {
    return daysBetweenDateAndReportingDate(new Date(), reportingDate, estimation);
}

const MAX_ESTIMATION_DAYS = 10;

export function estimateVaccinationsSinceReportingDate(reportingDate: Date, estimation: VaccineSDK.EstimationInfo) {
    const days = daysSinceReportingDate(reportingDate, estimation);
    return Math.round(Math.min(MAX_ESTIMATION_DAYS, days) * estimation.approximateDailyVaccinations);
}