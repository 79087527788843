








import { Component, Prop, Vue, VModel } from 'vue-property-decorator';

export interface Tab {
  id: string;
  title: string;
  iconClass: string;
}

@Component
export default class TabBar extends Vue {
  @Prop({ default: [] })
  tabs: Tab[];

  @VModel()
  selectedTabID: string;
}
