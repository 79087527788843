





































import { VaccineSDK } from '@/sdk';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Country from './Country.vue';

interface SortType {
    title: string;
    sort: (a: VaccineSDK.CountryInfo, b: VaccineSDK.CountryInfo) => number;
}

interface AscendingSortType extends SortType {
    autoOrderingOverride?: { asc: string | boolean, desc: string | boolean };
    reverseAscendingDescendingOrder?: boolean;
}

@Component({
    components: { Country }
})
export default class Sidebar extends Vue {
    @Prop()
    data: VaccineSDK.CountryInfo[] | null;

    @Prop({ default: false })
    isLoading: boolean;

    isInfoShowing = false;
    isSortShowing = false;
    selectedSortTypeIndex = 0;

    get selectedSortType() {
        return this.sortTypes[this.selectedSortTypeIndex];
    }

    get sortedData() {
        if (!this.data) return null;
        return this.data.sort(this.selectedSortType.sort);
    }

    get sortTypes(): SortType[] {
        var types: SortType[] = [];
        for (var type of this.ascendingSortTypes) {
            const sort = type.sort;
            const orig = {
                title: `${type.title}: ${type.autoOrderingOverride?.asc || "Ascending"}`,
                sort: sort
            };
            const reversed = type.reverseAscendingDescendingOrder == true;
            if (!reversed && type.autoOrderingOverride?.asc !== false) types.push(orig);
            if (type.autoOrderingOverride?.desc !== false) types.push({
                title: `${type.title}: ${type.autoOrderingOverride?.desc || "Descending"}`,
                sort: (a, b) => sort(b, a)
            });
            if (reversed && type.autoOrderingOverride?.asc !== false) types.push(orig);
        }
        return types;
    }

    get ascendingSortTypes(): AscendingSortType[] {
        return [
            {
                title: "Total Administered",
                autoOrderingOverride: { asc: "Least to Most", desc: "Most to Least" },
                sort: (a, b) => {
                    return a.vaccinations.administered - b.vaccinations.administered
                },
                reverseAscendingDescendingOrder: true
            },
            {
                title: "% Vaccinated",
                autoOrderingOverride: { asc: "Least to Most", desc: "Most to Least" },
                sort: (a, b) => {
                    return (a.vaccinations.administered / a.population) - (b.vaccinations.administered / b.population)
                },
                reverseAscendingDescendingOrder: true
            },
            {
                title: "Reporting Time",
                autoOrderingOverride: { asc: false, desc: "Latest" },
                sort: (a, b) => {
                    return new Date(a.vaccinations.asOf).getTime() - new Date(b.vaccinations.asOf).getTime();
                },
                reverseAscendingDescendingOrder: true
            },
            {
                title: "Country Name",
                autoOrderingOverride: { asc: "A-Z", desc: "Z-A" },
                sort: (a, b) => {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                }
            }
        ]
    }

    pauseUpdatesTimeout: number | null = null;
    @Watch("selectedSortTypeIndex")
    onSelectedSortTypeChange() {
        this.$emit("pause-updates");
        if (this.pauseUpdatesTimeout) clearInterval(this.pauseUpdatesTimeout);
        this.pauseUpdatesTimeout = setTimeout(() => this.$emit("resume-updates"), 250 + 100);
    }
}
