










import { Component, Prop, Vue, VModel } from 'vue-property-decorator';
import { VaccineSDK } from "@/sdk"
import VaccinationListItem from "./VaccinationListItem.vue";

@Component({
  components: { VaccinationListItem }
})
export default class Country extends Vue {
  @Prop()
  info: VaccineSDK.CountryInfo;
}
