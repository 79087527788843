





































import { Component, Vue } from 'vue-property-decorator';
import { GChart } from 'vue-google-charts';
import Sidebar from './components/Sidebar.vue';
import TabBar from './components/TabBar.vue';
import VaccinationListItem from "./components/VaccinationListItem.vue";
import Country from "./components/Country.vue";
import { VaccineSDK } from "./sdk/";
import { estimateVaccinationsSinceReportingDate } from "./util/Estimation";

interface MapColouringMode {
  title: string;
  getMaximum: (data: VaccineSDK.CountryInfo[]) => number;
}

@Component({
  components: {
    GChart,
    Sidebar,
    TabBar,
    VaccinationListItem,
    Country
  }
})
export default class App extends Vue {
  selectedTabID = "map";
  includesEstimates = true;

  isLoading = true;
  data: VaccineSDK.VaccineData | null = null;
  dataIncludingEstimations: VaccineSDK.CountryInfo[] | null = null;
  mapDataCache: VaccineSDK.CountryInfo[] | null = null;
  shouldUpdateSidebar: boolean = true;

  _timers: number[] | null = null;

  $refs: {
    map: Vue;
  }

  created() {
    this.loadData();
  }

  mounted() {
    this._timers = [
      setInterval(() => this.calculateLatestEstimates(), 1000 / 15), // 30 FPS
      setInterval(() => this.updateMapCache(), 1000 * 30) // every 30 seconds
    ];
  }

  beforeUnmount() {
    if (this._timers) {
      this._timers.forEach(t => clearInterval(t));
      this._timers = null;
    }
  }

  async loadData() {
    this.isLoading = true;
    try {
      this.data = await VaccineSDK.getVaccineData();
      this.calculateLatestEstimates();
      this.updateMapCache();
    } catch (e) {
      console.error("Error while retrieving data:", e);
    }
    this.isLoading = false;
    setTimeout(() => this.loadData(), 1000 * 60 * 5); // every 5 minutes.
  }

  get currentData() {
    return (this.includesEstimates ? this.dataIncludingEstimations : this.data?.countries) || null;
  }

  toggleEstimation() {
    this.includesEstimates = !this.includesEstimates;
    this.updateMapCache();
  }

  pauseSidebarUpdates() {
    this.shouldUpdateSidebar = false;
  }

  resumeSidebarUpdates() {
    this.shouldUpdateSidebar = true;
    this.calculateLatestEstimates();
  }

  calculateLatestEstimates() {
    if (!this.shouldUpdateSidebar) return;
    if (!this.data || !this.includesEstimates) return;
    const now = new Date();
    this.dataIncludingEstimations = this.data.countries.map(d => {
      if (!d.vaccinations.estimation) return d;
      const latestReportDate = new Date(d.vaccinations.asOf);
      if (now <= latestReportDate) return d;
      const estimatedCount = estimateVaccinationsSinceReportingDate(latestReportDate, d.vaccinations.estimation);
      const newObj = JSON.parse(JSON.stringify(d));
      newObj.vaccinations.administered = Math.min(newObj.population, newObj.vaccinations.administered + estimatedCount);
      newObj.vaccinations.estimation = null;
      return newObj;
    });
  }

  get worldVaccinations(): VaccineSDK.VaccinationsInfo | null {
    if (!this.currentData) return null;
    return {
      administered: this.currentData.map(c => c.vaccinations.administered).reduce((a, b) => a + b, 0),
      asOf: "", // irrelevant, since we're using estimations from countries
      estimation: null
    }
  }

  // MARK: - Map

  updateMapCache() {
    this.mapDataCache = this.currentData;
  }

  get chartData() {
    const header: any[] = ['Country', 'Vaccinated', { role: "tooltip", p: { html: true } }];
    return this.mapDataCache ? [header].concat(this.mapDataCache.map(c => {
      return [c.code, c.vaccinations.administered / c.population, `<span class="region-ref" iso-code="${c.code}"></span>`]
    })) : [header];
  }

  get chartOptions() {
    return {
      enableRegionInteractivity: true,
      backgroundColor: "#191c1f",
      datalessRegionColor: "#262c2e",
      colorAxis: { minValue: 0, maxValue: this.mapDataCache ? this.selectedMapColouringMode.getMaximum(this.mapDataCache) : 1, colors: ["#262c2e", "#4780AC"] },
      legend: "none",
      tooltip: { isHtml: true }
    };
  }

  onChartMouseMove() {
    const hoveredISOCode = this.$refs.map.$el.querySelector(".google-visualization-tooltip-item .region-ref")?.getAttribute("iso-code") || null;
    if (this.hoveredRegionISOCode == hoveredISOCode) return;
    console.log("New hover:", hoveredISOCode)
    this.hoveredRegionISOCode = hoveredISOCode;
  }

  hoveredRegionISOCode: string | null = null;

  get hoveredCountry() {
    return this.hoveredRegionISOCode && this.currentData && this.currentData.find(c => c.code == this.hoveredRegionISOCode);
  }

  selectedMapColouringModeIndex = 1;

  get mapColouringModes(): MapColouringMode[] {
    return [
      { title: "Entire Population", getMaximum: (_) => 1 },
      { title: "Herd Immunity (70%)", getMaximum: (_) => 0.7 },
      {
        title: "Relative to Leading Country (% vaccinated)",
        getMaximum: (data) => data.map(d => d.vaccinations.administered / d.population).sort((a, b) => b - a)[0]
      }
    ]
  }

  get selectedMapColouringMode() {
    return this.mapColouringModes[this.selectedMapColouringModeIndex];
  }
}
