




















import { Component, Prop, Vue, VModel } from 'vue-property-decorator';
import { VaccineSDK } from "@/sdk"

@Component
export default class VaccinationListItem extends Vue {
  @Prop()
  title: string;

  @Prop()
  vaccinations: VaccineSDK.VaccinationsInfo;

  @Prop()
  population: number;

  @Prop({ default: false })
  hoverable: boolean;

  get shouldShowWarnings() {
    return this.population > 2500 && (this.population - this.vaccinations.administered) > 500;
  }

  get percentage() {
    return this.vaccinations.administered / this.population;
  }

  get daysSinceReportingDate() {
    const reportingDate = new Date(this.vaccinations.asOf);
    const dayMs = 1000 * 60 * 60 * 24;
    return Math.floor(Math.abs((reportingDate.getTime() - Date.now()) / dayMs))
  }
}
