import { API_ENDPOINT } from "@/Constants";
import axios from "axios";

export namespace VaccineSDK {
    export interface EstimationInfo {
        assumedHoursUTC: { start: number, end: number };
        approximateDailyVaccinations: number;
    }

    export interface VaccinationsInfo {
        administered: number;
        asOf: string;
        estimation: EstimationInfo | null;
    }

    export interface CountryInfo {
        code: string, name: string, population: number;
        vaccinations: VaccinationsInfo
    }

    export interface VaccineData {
        countries: CountryInfo[];
        world: { population: number };
        attributions: any[];
    }

    export async function getVaccineData(): Promise<VaccineData> {
        return (await axios.get(API_ENDPOINT)).data;
    }
};